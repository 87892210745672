import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import PageWrapper from "@components/pageWrapper"
import { VideoPlayer } from "@components/video"

import {
  ScrollToButton, LearnMoreButton,
} from "@components/buttons"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const IndexPage = () => {
  return (
    <PageLayout>
      <SEO
        title="The Aloha State"
        ogDescription="The Aloha State is an R&B kinda thing from Atlanta, GA."
        image="https://thealohastate.us/images/theetherfamilypresents-pressshot-2010.jpg"
        url="https://thealohastate.us"
      />
      <PageWrapper color="charcole">
        <TextBlock
          textPadded
          textLeft={
            <div>
              <h1>The Aloha State</h1>
              <p>
                <a href="https://jimmyether.com">Jimmy Ether</a> helmed project that combines his love for underground boom-bap, soulful harmonies, lyrical tendrils, and warped guitar lines. Most material is the product of his <a href="https://psycheler.com" target="_blank">Psycheler music production framework</a>.
              </p>
              <p>
                The debut maxi-single is in the works and will be out soon. Stay tuned!
              </p>
            </div>
          }
          textRight={
            <div>
              <img src="/images/aloha-state-pineapple.gif" alt="The Aloha State... Atlanta, GA" />
            </div>
          }
        />
      </PageWrapper>
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h2>Get in touch!</h2>
            <RequestMoreInfo />
          </div>
        }
      />
    </PageLayout>
  )
}

export default IndexPage
